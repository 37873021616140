<template>
  <div class="constructor-layout-simple bg-cercles" id="constructor" v-show="show">
    <header class="constructor-layout-simple__header">
      <div class="added-alert">
        <div class="bb-container bb-container--lista">
          <div class="added-alert__center">
            <portal-target name="alerts_constructor_page"></portal-target>
          </div>
        </div>
      </div>

      <!-- Sección app download //////////// -->
      <section class="constructor-layout-simple__app" v-if="listActive">
        <banner-download-mobile-apps></banner-download-mobile-apps>
      </section>
    </header>
    <!-- Sección Constructor //////////// -->
    <div class="bb-container bb-container--lista">
      <main
        class="constructor-layout-simple__content constructor-layout-simple__content--compact"
      >
        <Notification
          v-if="showNotification"
          tipo="error"
          @close-notification="closeNotification()"
        >
          {{ errorMessage }}
        </Notification>

        <div
          class="constructor-layout-simple__search constructor-layout-simple__search--compact"
        >
          <SearchConstructorInternational @error="handleError" />
        </div>
        <div class="constructor-layout-simple__howto">
          <div class="search-hiw">
            <div class="search-hiw__col">
              <!-- <mq-layout :mq="['m480', 'm576', 'm768']">
                <header class="search-hiw__title">¿Cómo funciona?</header>
              </mq-layout> -->
              <mq-layout mq="m992+">
                <picture class="search-hiw__picture" v-if="countryCode == 'FR'">
                  <img
                    src="../../assets/img/covers/constructor-hiw.fr.jpg"
                    :alt="$t('constructor.howItWorks')"
                  />
                </picture>
                <picture class="search-hiw__picture" v-else-if="countryCode == 'IT'">
                  <img
                    src="../../assets/img/covers/constructor-hiw.it.jpg"
                    :alt="$t('constructor.howItWorks')"
                  />
                </picture>
                <picture class="search-hiw__picture" v-else>
                  <img
                    src="../../assets/img/covers/cover-elige-compact-int-inline.svg"
                    :alt="$t('constructor.howItWorks')"
                  />
                </picture>
              </mq-layout>
            </div>
            <div class="search-hiw__col">
              <header class="search-hiw__title">{{ $t("constructor.howItWorks") }}</header>
              <!-- <mq-layout mq="m992+">
                <div class="search-hiw__subtitle">
                  Guarda cosas en tu lista de HelloBB así:
                </div>
              </mq-layout> -->
              <main class="search-hiw__items">
                <div class="search-hiw__item">
                  <div class="search-hiw__step">
                    <div class="search-hiw__cercle">1</div>
                  </div>
                  <div class="search-hiw__desc">
                    {{ $t("constructor.howItWorks1") }}
                  </div>
                </div>
                <div class="search-hiw__item">
                  <div class="search-hiw__step">
                    <div class="search-hiw__cercle">2</div>
                  </div>
                  <div class="search-hiw__desc">{{ $t("constructor.howItWorks2") }}</div>
                </div>
                <div class="search-hiw__item">
                  <div class="search-hiw__step">
                    <div class="search-hiw__cercle">3</div>
                  </div>
                  <div class="search-hiw__desc">{{ $t("constructor.howItWorks3") }}</div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </main>

      <FirstLoginModalInternational
        v-if="showFirstLoginModal"
        @close="closeModal()"
      ></FirstLoginModalInternational>
    </div>
  </div>
</template>

<script>
import BannerDownloadMobileApps from "./components/BannerDownloadMobileApps";
import CanGetOwnerList from "../../Mixins/CanGetOwnerList";
import FirstLoginModalInternational from "./components/FirstLoginModalInternational.vue";
import { mapGetters } from "vuex";

import SearchConstructorInternational from "./components/SearchConstructorInternational";
import Notification from "../../components/ui/Notification.vue";

export default {
  name: "ConstructorInternationalPage",
  mixins: [CanGetOwnerList],
  components: {
    Notification,
    BannerDownloadMobileApps,
    SearchConstructorInternational,
    FirstLoginModalInternational,
  },
  metaInfo() {
    return {
      title: "Construye tu lista | HelloBB",
    };
  },
  data() {
    return {
      show: false,
      showFirstLoginModal: false,
      error: this.$route.params.error || false,
      errorMessage: this.$route.params.message || "",
      showNotification: this.$route.params.message || "",
    };
  },
  computed: {
    ...mapGetters({
      listConstructor: "listConstructor",
      limit: "limit",
      listActive: "listActive",
      countryCode: "countryCode",
    }),
    hasProducts() {
      if (this.list) {
        if (this.list.products) {
          return this.list.products.length > 0;
        }
      }
      return false;
    },
  },
  async serverPrefetch() {
    //await this.$store.dispatch("getListConstructor");
    //console.log("serverPrefetch", this.$store.state.listConstructor)
  },
  async mounted() {
    console.log("mounted", this.$store.state.limit);
    console.log("mounted", this.$store.state.listConstructor);
    
    //I think that this is not necessary here cause there is no 'constructor'
    //await this.$store.dispatch("setLimitSectionsToShow");
    
    // if (this.$utils.isEmpty(this.listConstructor)) {
    //   await this.$store.dispatch("getListConstructor");
    // }

    //I think that this is not necessary here cause there is no 'constructor'
    //await this.$store.dispatch("getListConstructor");
    
    this.show = true;
    this.$store.commit("setCanScroll");

    // let modalShownCookie = this.getCookie("modalShown");
    // console.log("modalShownCookie", modalShownCookie);
    // // Verificar si la cookie modalShown existe y no está caducada
    // // ALERTA: código desactivado temporalmente
    // if (modalShownCookie && modalShownCookie !== "undefined") {
    //   this.showFirstLoginModal = true;
    // }
  },
  methods: {
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) {
        return parts.pop().split(";").shift();
      }
    },
    closeModal() {
      // Cierra el modal y elimina la cookie
      this.showFirstLoginModal = false;
      this.removeCookie("modalShown");
    },
    removeCookie(name) {
      // Elimina la cookie del local storage
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    },
    closeNotification() {
      this.errorMessage = "";
      this.showNotification = false;
    },
    handleError(message) {
      this.error = true;
      this.errorMessage = message;
      this.showNotification = message || "";
    },
  },
  created() {
    if (this.error) {
      this.showNotification = true;
    }
  },
};
</script>
